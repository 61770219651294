import { render, staticRenderFns } from "./AxZNewreportList.vue?vue&type=template&id=95bfa656&scoped=true"
import script from "./AxZNewreportList.vue?vue&type=script&lang=js"
export * from "./AxZNewreportList.vue?vue&type=script&lang=js"
import style0 from "./AxZNewreportList.vue?vue&type=style&index=0&id=95bfa656&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "95bfa656",
  null
  
)

export default component.exports