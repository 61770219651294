<template>
    <div>
        <!--AxZNewreportList.vue
        {
            pageFlag: true,
            id: "ax_z_newreport",
            title: "",
            name: "ax_z_newreport",
            icon: "el-icon-document",
            component: "views/zb/AxZNewreportList.vue",
            componentdetail: "views/zb/AxZNewreportDetail.vue",
            visible: true
        }
        -->
        <el-container>
            <el-main>
                <el-button-group>
                    <el-button type="" size="mini" @click="syslistquery">查询</el-button>
                     
                    <vxe-button size="mini" content="导出excel" @click="exportToExcel"></vxe-button>
                    
                    <!-- <el-button type="" size="mini" @click="syslistadd">新增</el-button> -->
                </el-button-group>
                <vxe-form size="mini">
                    <vxe-form-item title="日期" field="日期" :item-render="{}" :span="3">
                        <template #default="{ data }">
                            <vxe-input v-model="p.datee" value-format="yyyy-MM-dd" type="date" size="mini" placeholder="年份"></vxe-input>
                        </template>
                    </vxe-form-item>

                    <vxe-form-item title="~" field="结束日期" :item-render="{}" :span="3">
                        <template #default="{ data }">
                            <vxe-input  v-model="p.dates" value-format="yyyy-MM-dd" type="date" size="mini" placeholder="年份"></vxe-input>
                        </template>
                    </vxe-form-item>

                    <vxe-form-item title="要求交货日期" field="日期" :item-render="{}" :span="4">
                        <template #default="{ data }">
                            <vxe-input v-model="p.yqjhrqdatee" value-format="yyyy-MM-dd" type="date" size="mini" placeholder="年份"></vxe-input>
                        </template>
                    </vxe-form-item>

                    <vxe-form-item title="~" field="结束日期" :item-render="{}"  :span="3">
                        <template #default="{ data }">
                            <vxe-input  v-model="p.yqjhrqdates" value-format="yyyy-MM-dd" type="date" size="mini" placeholder="年份"></vxe-input>
                        </template>
                    </vxe-form-item>
                

                  
                    <vxe-form-item title="排产单号" field="pcdh" :item-render="{}" style="width:60px;" :span="3">
                        <vxe-input v-model="p.pcdh" size="mini" placeholder="排产单号"></vxe-input>
                    </vxe-form-item>
                    <vxe-form-item title="类型" field="kind" :item-render="{}" style="width:60px;" :span="3">
                        <vxe-input v-model="p.kind" size="mini" placeholder="类型"></vxe-input>
                    </vxe-form-item>
                    <vxe-form-item title="班组" field="bz" :item-render="{}" style="width:60px;" :span="3">
                        <vxe-input v-model="p.bz" size="mini" placeholder="班组"></vxe-input>
                    </vxe-form-item>
                    <vxe-form-item title="工艺类型" field="gykind" :item-render="{}" style="width:60px;" :span="3">
                        <vxe-input v-model="p.gykind" size="mini" placeholder="工艺类型"></vxe-input>
                    </vxe-form-item>
                    <vxe-form-item title="工程名称" field="gcmc" :item-render="{}" style="width:100px;">
                        <vxe-input v-model="p.gcmc" size="mini" placeholder="工程名称"></vxe-input>
                    </vxe-form-item>
                    <vxe-form-item title="业务员" field="gcmc" :item-render="{}" style="width:100px;">
                        <vxe-input v-model="p.clerk" size="mini" placeholder="业务员"></vxe-input>
                    </vxe-form-item>

                    <vxe-form-item title="组装是否完成" field="ifzz" :item-render="{}"  style="width:20px;">
                        <template #default="{ data }">
                            <el-select  v-model="p.ifzz" size="mini" filterable placeholder="请选择">
                                <el-option v-for="item in selectisrobot" :key="item.id" :label="item.name" :value="item.id">
                                </el-option>
                            </el-select>
                        </template>
                    </vxe-form-item>
                    <vxe-form-item title="入库是否完成" field="ifrk" :item-render="{}" style="width:20px;">
                        <template #default="{ data }">
                            <el-select  v-model="p.ifrk" size="mini" filterable placeholder="请选择">
                                <el-option v-for="item in selectisrobot" :key="item.id" :label="item.name" :value="item.id">
                                </el-option>
                            </el-select>
                        </template>
                    </vxe-form-item>
                </vxe-form>
                <input disabled v-model="newtime" size="mini" placeholder="刷新时间" style="width:200px;font-weight: 700;">
                <vxe-table align="center" :loading = "pictLoading" :cell-style="cellClassName" 
                    :header-cell-style="headerCellClassName"
                    :column-config="{resizable: true}"
                    ref="a"
                    show-footer :footer-method="footerMethod"
                    show-overflow highlight-current-row border show-header-overflow 
                    highlight-hover-row size="mini" :data="rs" :height="meta.h">
                    <!-- <vxe-table-column field="seq" title="序号" width="50"></vxe-table-column> -->
                    <vxe-column fixed="left"  type="seq" width="50" title="序号"></vxe-column>
                    <vxe-table-column fixed="left" field="pcdh" title="排产单号" width="100"></vxe-table-column>
                    <vxe-table-column fixed="left" field="gcmc" title="工程名称" width="200"></vxe-table-column>
                    <vxe-table-column fixed="left" field="clerk" title="业务员" width="100"></vxe-table-column>
                    <vxe-table-column fixed="left" field="kind" title="类型" width="50"></vxe-table-column>
                    <vxe-table-column fixed="left" field="bz" title="班组" width="75"></vxe-table-column>
                    <vxe-table-column fixed="left" field="quantity" title="数量（个)" width="85"></vxe-table-column>
                    <vxe-table-column fixed="left" field="amountmoney" title="产值（元）" width="85"></vxe-table-column>
                    <vxe-table-column fixed="left" field="xdrq" title="下单日期" width="95"></vxe-table-column>
                    <vxe-table-column fixed="left"  field="yqjhrq" title="要求交货时间" width="95"></vxe-table-column>
                    <vxe-table-column fixed="left" field="gykind" title="工艺类型" width="95"></vxe-table-column>
                    <vxe-colgroup title="开料" align="center" style="background-color: aqua;color: aqua;">
                        <vxe-table-column field="klywcsl" title="已完成数量" width="95"></vxe-table-column>
                        <vxe-table-column field="klwwcsl" title="未完成数量" width="95"></vxe-table-column>
                        <vxe-table-column field="klmbwcsj" title="目标完成时间" width="100"></vxe-table-column>
                        <vxe-table-column field="klsjwcsj" title="实际完成时间" width="100"></vxe-table-column>
                    </vxe-colgroup>
                    <vxe-colgroup title="组装" align="center">
                        <vxe-table-column field="zzywcsl" title="已完成数量" width="95"></vxe-table-column>
                        <vxe-table-column field="zzwwcsl" title="未完成数量" width="95"></vxe-table-column>
                        <vxe-table-column field="zzmbwscj" title="目标完成时间" width="100"></vxe-table-column>
                        <vxe-table-column field="zzsjwcsj" title="实际完成时间" width="100"></vxe-table-column>
                    </vxe-colgroup>
                    <vxe-colgroup title="喷涂" align="center">
                        <vxe-table-column field="ptywcsl" title="已完成数量" width="95"></vxe-table-column>
                        <vxe-table-column field="ptwwcsl" title="未完成数量" width="95"></vxe-table-column>
                        <vxe-table-column field="ptmbwcsj" title="目标完成时间" width="100"></vxe-table-column>
                        <vxe-table-column field="ptsjwcsj" title="实际完成时间" width="100"></vxe-table-column>
                    </vxe-colgroup>
                    <vxe-colgroup title="质检" align="center" >
                        <vxe-table-column field="zjywcsl" title="已完成数量" width="95"></vxe-table-column>
                        <vxe-table-column field="zjwwcsl" title="未完成数量" width="95"></vxe-table-column>
                        <vxe-table-column field="zjmbwcsj" title="目标完成时间" width="100"></vxe-table-column>
                        <vxe-table-column field="zjsjwcsj" title="实际完成时间" width="100"></vxe-table-column>
                    </vxe-colgroup>
                    
                    <vxe-colgroup title="产成品入库单" align="center">
                        <vxe-table-column field="rkywcsl" title="已完成数量" width="95"></vxe-table-column>
                        <vxe-table-column field="ruwwcsl" title="未完成数量" width="95"></vxe-table-column>
                        <vxe-table-column field="rumbwcsj" title="目标完成时间" width="100"></vxe-table-column>
                        <vxe-table-column field="rksjwcsj" title="实际完成时间" width="100"></vxe-table-column>
                    </vxe-colgroup>
                    <vxe-colgroup title="销货单" align="center">
                        <vxe-table-column field="xhywcsl" title="已完成数量" width="95"></vxe-table-column>
                        <vxe-table-column field="xhwwcsl" title="未完成数量" width="95"></vxe-table-column>
                        <vxe-table-column field="xhmbwcsj" title="目标完成时间" width="100"></vxe-table-column>
                        <vxe-table-column field="xhsjwcsj" title="实际完成时间" width="100"></vxe-table-column>
                    </vxe-colgroup>
                    <vxe-table-column :visible="false" field="klstatus" title="klstatus" width="100"></vxe-table-column>
                    <vxe-table-column :visible="false" field="zzstatus" title="zzstatus" width="100"></vxe-table-column>
                    <vxe-table-column :visible="false" field="ptstatus" title="ptstatus" width="100"></vxe-table-column>
                    <vxe-table-column :visible="false" field="zjstatus" title="zjstatus" width="100"></vxe-table-column>
                    <vxe-table-column :visible="false" field="rkstatus" title="rkstatus" width="100"></vxe-table-column>
                    <vxe-table-column :visible="false" field="xhstatus" title="xhstatus" width="100"></vxe-table-column>
                </vxe-table>
                <vxe-pager :align="'left'" background :current-page.sync="p1.page.current" :page-size.sync="p1.page.size" :total="p1.page.total" @page-change="pagechange" :page-sizes=[100,500,1000,10000] :layouts="['PrevJump', 'PrevPage', 'JumpNumber', 'NextPage', 'NextJump', 'Sizes', 'FullJump', 'Total']">
                </vxe-pager>
            </el-main>
        </el-container>
    </div>
</template>
<script>
    import ax from "@/js/ax.js";
    import Vue from "vue";
    import VXETable from 'vxe-table';
    import { VxeTablePropTypes, VxeToolbar, VxeInput, VxeTable, VxeColumn } from 'vxe-table';
    import VXETablePluginExportXLSX from 'vxe-table-plugin-export-xlsx';
    VXETable.use(VXETablePluginExportXLSX);
    export default {
        name: "ax_z_newreportlist",
        extends: ax.control1(),
        data() {
            return {
                p: {  
                    datee:this.getdatess(),
                    dates:this.getdates(),
                    kind:"",
                    pcdh:"",
                    bz:"",
                    gykind:"",
                    gcmc:"",
                    clerk:"",
                    ifzz:2,
                    ifrk:2,
                    yqjhrqdatee:this.getpredates(),
                    yqjhrqdates:this.getaftdatess(),

                     
                },
                selectisrobot:[{id:0,name:"否"},{id:1,name:"是"},{id:2,name:"ALL"}],
                newtime:"",
                pictLoading:true,
                model: "zb",
                settings: "axZNewreport",
                vouchertype: "list",
            }
        },
        methods: {
        // rowstyle({ row, rowIndex, $rowIndex }) {
        // },
        getdates() {
            var now2 = new Date();
            return ax.date.getnow(now2)
        },
        getdatess() {
            var now2 = new Date();
            now2.setMonth(now2.getMonth() - 1);
            return ax.date.getnow(now2)
            
        },
        getpredates() {
            var now2 = new Date();
            now2.setMonth(now2.getMonth() - 1);
            return ax.date.getnow(now2)
        },
        getaftdatess() {
            var now2 = new Date();
            now2.setMonth(now2.getMonth() + 1);
            return ax.date.getnow(now2)
            
        },
        headerCellClassName({ column }){
                // console.log(column.property);
            // console.log(column);
            if (column.title === '开料') {
                // console.log(column);
                // console.log(column.property);
                return {"background-color":"#BBFFEE"};
            }
        
            if(column.property==="klywcsl"||column.property==="klwwcsl"||column.property==="klmbwcsj"||column.property==="klsjwcsj"){
                return {"background-color":"#BBFFEE"};
            }
            if (column.title === '组装') {
     
                return {"background-color":"rgb(229, 234, 122)"};
            }

            if(column.property==="zzywcsl"||column.property==="zzwwcsl"||column.property==="zzmbwscj"||column.property==="zzsjwcsj"){
                return {"background-color":"rgb(229, 234, 122)"};
            }

         

            if (column.title === '喷涂') {
                return {"background-color":"rgb(229, 171, 171)"};
            }
            if(column.property==="ptywcsl"||column.property==="ptwwcsl"||column.property==="ptmbwcsj"||column.property==="ptsjwcsj"){
                return {"background-color":"rgb(229, 171, 171)"};
            }


            if (column.title === '质检') {
                return {"background-color":"rgb(162, 198, 239)"};
            }


            if(column.property==="zjywcsl"||column.property==="zjwwcsl"||column.property==="zjmbwcsj"||column.property==="zjsjwcsj"){
                return {"background-color":"rgb(162, 198, 239)"};
            }
            



            if (column.title === '产成品入库单') {
          
                return {"background-color":"#BBFFEE"};
            }
            if(column.property==="rkywcsl"||column.property==="ruwwcsl"||column.property==="rumbwcsj"||column.property==="rksjwcsj"){
                return {"background-color":"#BBFFEE"};
            }

            if (column.title === '销货单') {
                return {"background-color":"rgb(229, 234, 122)"};
            }
            if(column.property==="xhywcsl"||column.property==="xhwwcsl"||column.property==="xhmbwcsj"||column.property==="xhsjwcsj"){
                return {"background-color":"rgb(229, 234, 122)"};
            }
            return null
        },
        syslistquery() {
            this.pictLoading=true;
            let _this = this;
            let pp = {
                details: this.p,
                page: this.p1.page
            }
            ax.base.post("/" + this.model + "/" + this.settings + "/syslistquery", pp).then(data => {
                console.log(data.data);
                _this.rs = data.data.records;
                _this.p1.page.total = data.data.total;
                this.pictLoading=false;

            }).catch(data => {
                this.pictLoading=false;
                _this.$XModal.message({ message: data.data, status: 'error' })
            })

            ax.base.post("/" + this.model + "/" + this.settings + "/getCreateTime", '').then(data => {
                console.log(data.data);
                // this.newtime = "11111";
                this.newtime = "刷新时间："+data.data.createtime;
            }).catch(data => {
                _this.$XModal.message({ message: data.data, status: 'error' })
            })


        },
        footerMethod ({ columns, data }) {
            console.log(data);
            return [
                columns.map((column, columnIndex) => {
                    
                    if (columnIndex === 0) {
                        return '合计'
                    }
                    if ([ "quantity","amountmoney","klywcsl","klwwcsl","zzywcsl",
                    "zzwwcsl","ptywcsl","ptwwcsl","zjywcsl","zjwwcsl","rkywcsl","ruwwcsl",
                    "xhywcsl","xhwwcsl"].includes(column.property)) {
                        return this.handleSum(data, column.property)
                    }
                    return '-'
                })
            ]
            },
            handleSum(list, field){
                var total = 0
                for(var index=0;index<list.length;index++){
                    if(list[index][field]=="/"){
                        total=total+0;
                    }else{
                        total += Number(list[index][field] || 0)
                    }
                }
                var totalstr = total.toLocaleString();
                return totalstr;
            },
        exportToExcel() {
            // console.log(this.$refs.a);
            this.$refs.a.exportData({
                filename: '订单实时完成表',
                sheetName: '订单实时完成表',
                type: 'xlsx',
            });
        },
        cellClassName ({ row, rowIndex, column, columnIndex }) {
            let rowstyle;
            // console.log(column.property);
            // console.log(row);
            if(column.property==="klsjwcsj"){
                console.log(row["klstatus"]);
                if(row["klstatus"]>0){
                    rowstyle = {"color":"red"};
                }else{
                    rowstyle = null;
                }
            }

            if(column.property==="zzsjwcsj"){
                console.log(row["zzsjwcsj"]);
                if(row["zzstatus"]>0){
                    rowstyle = {"color":"red"};
                }else{
                    rowstyle = null;
                }
            }

            if(column.property==="ptsjwcsj"){
                // console.log(row["klstatus"]);
                if(row["ptstatus"]>0){
                    rowstyle = {"color":"red"};
                }else{
                    rowstyle = null;
                }
            }

            if(column.property==="zjsjwcsj"){
                // console.log(row["klstatus"]);
                if(row["zjstatus"]>0){
                    rowstyle = {"color":"red"};
                }else{
                    rowstyle = null;
                }
            }

            if(column.property==="rksjwcsj"){
                // console.log(row["rkstatus"]);
                if(row["rkstatus"]>0){
                    rowstyle = {"color":"red"};
                }else{
                    rowstyle = null;
                }
            }

            if(column.property==="xhsjwcsj"){
                // console.log(row["rkstatus"]);
                if(row["xhstatus"]>0){
                    rowstyle = {"color":"red"};
                }else{
                    rowstyle = null;
                }
            }
            
            return rowstyle;
        },
    },
    }
</script>
<style scoped>
    .cell{
        background-color: rgb(229, 234, 122);
    }
</style>